<template lang="pug">
#brand-agency-template-sharing.brand-widgets.brand-new-widgets
  .widget-template.widget-template-bar.d-flex.pb-0.px-0.mt-6.ml-0.mb-4
    .col-9.justify-content-start.d-flex.flex-row.px-0.align-items-end
      .t2.widget-template-tab(@click="tab = 'live'" :class="{ active: isLiveTab }") {{ $t('templateManager.tabs.active') }}
      .widget-template-tab-sep
      .t2.widget-template-tab(@click="tab = 'draft'" :class="{ active: isDraftTab }") {{ $t('templateManager.tabs.draft') }}
      template(v-if="isAffiliate")
        .widget-template-tab-sep
        .t2.widget-template-tab(
          @click="tab = 'partnerFeatured'"
          :class="{ active: isPartnerFeaturedTab }"
        ) {{ $t('templateManager.tabs.partnerFeatured') }}
      template(v-if="databaseId === 44")
        .widget-template-tab-sep
        .t2.widget-template-tab(@click="tab = 'unlisted'" :class="{ active: isUnlistedTab }") {{ $t('templateManager.tabs.unlisted') }}
        .widget-template-tab-sep
        .t2.widget-template-tab(@click="tab = 'useCase'" :class="{ active: isUseCaseTab }") {{ $t('templateManager.tabs.useCase') }}
        .widget-template-tab-sep
        .t2.widget-template-tab(@click="tab = 'collections'" :class="{ active: isCollectionsTab }") {{ $t('templateManager.tabs.collections') }}
        .widget-template-tab-sep
        .t2.widget-template-tab(@click="tab = 'segments'" :class="{ active: isSegmentsTab }") {{ $t('templateManager.tabs.segments') }}
    .col-3.d-flex.justify-content-end.px-0.pb-3
      om-button(primary v-if="isCollectionsTab" @click="$modal.show('new-template-collection')") {{ $t('templateManager.newCollection') }}
      om-button(primary v-else-if="isUseCaseTab" @click="$modal.show('new-use-case')") {{ $t('templateManager.newUseCase') }}
      om-button(
        primary
        v-else-if="isSegmentsTab"
        @click="$modal.show('segments-superadmin-modal', { isNew: true })"
      ) {{ $t('templateManager.newSegment') }}
      om-button(primary v-else @click="openTemplateModal") {{ $t('templateManager.new') }}

  .new-campaign-content
    .widget-template.d-flex.p-0.text-left.ml-0(:class="typeClass")
      .container-fluid
        .row
          .col-12
            AgencyTemplateFilter(
              v-if="isDraftTab || isLiveTab || isUnlistedTab || isPartnerFeaturedTab"
              :templateTypes="templateTypes"
              :templateGoals="templateGoals"
              :templateThemes="allTemplateThemes"
              :templateCategories="templateCategories"
              :selectedType.sync="type"
              :selectedCategories.sync="selectedCategories"
              :selectedGoals.sync="selectedGoals"
              :selectedUsers.sync="selectedUsers"
              :selectedThemes.sync="selectedThemes"
              :users="users"
              :showType="isLiveTab || isDraftTab || isUnlistedTab"
              :templateKind.sync="templateKind"
            )
        .row
          .col-md-12.col-lg-12.col-xl-12.px-0(v-if="isLiveTab")
            .widget-template-wrap.d-flex.justify-content-start
              draggable.widget-template-wrap.d-flex-1.justify-content-start.mx-0(
                v-model="templates.published"
                :fallbackTolerance="20"
                @change="onTemplateDragChange($event, 'published')"
                :disabled="isAffiliate"
              )
                advanced-template-card(
                  @observable="addObservable($event.$el)"
                  v-for="template in publishedTemplates"
                  @inited="updateDimensions"
                  :dimensions="boxDimensions"
                  :key="template._id"
                  :template="template"
                  @statusChanged="moveTemplateToCorrespondingArray"
                  :windowBasedHeight="windowBasedHeight"
                  :agency="isAgency"
                  :editable="canEdit(template)"
                  @delete="deleteConfirmOpen"
                  :users="users"
                  :selected="selectedTemplates.includes(template._id)"
                  @selected="templateSelected"
                  @unselected="templateUnselected"
                  :tab="tab"
                  :draggable="!isAffiliate"
                )
                CreateNewCard(:agency="isAgency")
          .col-md-12.col-lg-12.col-xl-12.px-0(v-if="isDraftTab")
            template(v-if="templates.draft && templates.draft.length")
              .widget-template-wrap.d-flex.justify-content-start
                advanced-template-card(
                  @observable="addObservable($event.$el)"
                  v-for="template in draftTemplatesByKind"
                  @inited="updateDimensions"
                  :dimensions="boxDimensions"
                  :key="template._id"
                  :template="template"
                  @statusChanged="moveTemplateToCorrespondingArray"
                  :windowBasedHeight="windowBasedHeight"
                  :agency="isAgency"
                  :editable="canEdit(template)"
                  @delete="deleteConfirmOpen"
                  :users="users"
                  :useCases="useCase"
                  :selected="selectedTemplates.includes(template._id)"
                  @selected="templateSelected"
                  @unselected="templateUnselected"
                  :tab="tab"
                )
                CreateNewCard(:agency="isAgency" :extra="{ universal: isUniversalTab }")
            template(v-else)
              .w-100.text-center(v-if="!templatesLoading") {{ $t('templateManager.noDraft') }}
            loading-logo(v-if="templatesLoading")
          .col-md-12.col-lg-12.col-xl-12.px-0(v-if="isUnlistedTab")
            template(v-if="templates.unlisted && templates.unlisted.length")
              .widget-template-wrap.d-flex.justify-content-start
                advanced-template-card(
                  @observable="addObservable($event.$el)"
                  v-for="template in unlistedTemplates"
                  @inited="updateDimensions"
                  :dimensions="boxDimensions"
                  :key="template._id"
                  :template="template"
                  @statusChanged="moveTemplateToCorrespondingArray"
                  :windowBasedHeight="windowBasedHeight"
                  :agency="isAgency"
                  :editable="canEdit(template)"
                  @delete="deleteConfirmOpen"
                  :users="users"
                  :useCases="useCase"
                  :selected="selectedTemplates.includes(template._id)"
                  @selected="templateSelected"
                  @unselected="templateUnselected"
                  :tab="tab"
                )
                CreateNewCard(:agency="isAgency" :extra="{ universal: isUniversalTab }")
            template(v-else)
              .w-100.text-center(v-if="!templatesLoading") {{ $t('templateManager.noUnlisted') }}
            loading-logo(v-if="templatesLoading")
          .col-md-12.col-lg-12.col-xl-12.px-0(v-if="isPartnerFeaturedTab")
            .widget-template-wrap.d-flex.justify-content-start
              advanced-template-card(
                @observable="addObservable($event.$el)"
                v-for="template in partnerFeaturedTemplates"
                @inited="updateDimensions"
                :dimensions="boxDimensions"
                :key="template._id"
                :template="template"
                @statusChanged="moveTemplateToCorrespondingArray"
                :windowBasedHeight="windowBasedHeight"
                :agency="isAgency"
                :editable="canEdit(template)"
                @delete="deleteConfirmOpen"
                :users="users"
                :selected="selectedTemplates.includes(template._id)"
                @selected="templateSelected"
                @unselected="templateUnselected"
                :tab="tab"
              )
          .col-md-12.col-lg-12.col-xl-12.px-0.pb-3(v-if="isUseCaseTab")
            GeneralFilter(:options="useCaseFilterOpts" :filter.sync="useCaseFilter")
            draggable(v-model="useCasesFiltered" @change="onUseCaseDragChange($event)")
              UseCaseRow(
                v-for="(item, i) in useCasesFiltered"
                :key="`use-case-${i}`"
                :data="item"
                @open="onOpenUseCase"
                @edit="onEditUseCase"
                ref="useCases"
                @delete="showUseCaseRemoveDialog"
              )
          .col-md-12.col-lg-12.col-xl-12.px-0.pb-3(v-if="isCollectionsTab")
            //- GeneralFilter(:options="useCaseFilterOpts" :filter.sync="useCaseFilter")
            draggable(
              v-model="templateCollections"
              @change="onTemplateCollectionDragChange($event)"
            )
              TemplateCollectionRow(
                v-for="(templateCollection, i) in templateCollections"
                :templateCollection="templateCollection"
                :key="`template-collection-${i}`"
                @edit="$modal.show('new-template-collection', { mode: 'edit', data: templateCollection })"
                @delete="showTemplateCollectionRemoveDialog"
              )
          .col-md-12.col-lg-12.col-xl-12.px-0.pb-3(v-if="isSegmentsTab")
            SegmentsTabContent
  transition(name="fade")
    om-notification-bar(v-if="selectedTemplates.length")
      .mr-3 {{ selectedTemplates.length }} templates selected
      om-link(@click="selectedTemplates = []") Deselect all
      om-link.ml-auto(@click="$modal.show('tag-template-modal', { selectedTemplates })") Add tags
  tag-template-modal(@deselectAll="selectedTemplates = []" @updateTemplates="updateTemplates")
  new-campaign
  new-template(
    :goals="allTemplateGoals"
    :categories="allTemplateCategories"
    :families="themes"
    @templateChanged="templateChanged"
    :_locale="locale"
    :users="users"
    :useCases="useCase"
  )
  new-use-case(
    :goals="mappedGoals"
    :tactics="mappedTactics"
    :industries="mappedIndustries"
    :templates="allTemplates.filter((tpl) => !!tpl.universal)"
  )
  new-template-collection-modal(
    :templateCollections="templateCollections"
    @refetch="fetchTemplateCollections"
    @update="updateTemplateCollection"
  )
  name-duplicate-template(@duplicated="templateDuplicated")
  om-modal(name="template-delete-confirm" :width="500" color="light")
    template(slot="modal-header")
      .row
        .col
          .title {{ $t('deleteTemplate') }}
      .brand-modal-action-icon(v-if="!inProgress")
        span.cursor-pointer(
          @click="$modal.hide('template-delete-confirm')"
          title="close"
          aria-hidden="true"
        )
          close-icon(:width="12" :height="12")
    template(slot="modal-body")
      .text-center {{ $t('templateManager.warnings.deleteTemplate') }}
    template(slot="modal-footer")
      .d-flex.justify-content-center
        om-button.mr-3(ghost :disabled="inProgress" @click="deleteTemplate") {{ $t('yes') }}
        om-button(primary :disabled="inProgress" @click="$modal.hide('template-delete-confirm')") {{ $t('no') }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import draggable from 'vuedraggable';
  import { mapState, mapGetters } from 'vuex';

  import ALL_TEMPLATES from '@/graphql/AllTemplates.gql';
  import ALL_TEMPLATE_GOALS from '@/graphql/AllTemplateGoals.gql';
  import ALL_TEMPLATE_THEMES from '@/graphql/AllTemplateThemes.gql';
  import ALL_TEMPLATE_CATEGORIES from '@/graphql/AllTemplateCategories.gql';
  import CHANGE_TEMPLATE_ORDER from '@/graphql/ChangeTemplateOrder.gql';
  import DELETE_TEMPLATE from '@/graphql/DeleteTemplate.gql';
  import GET_ALL_SUBACCOUNTS from '@/graphql/GetAllSubAccount.gql';
  import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';
  import GET_USE_CASE from '@/graphql/GetUseCase.gql';
  import REMOVE_USE_CASE from '@/graphql/RemoveUseCase.gql';
  import CHANGE_USECASE_ORDER from '@/graphql/ChangeUseCaseOrder.gql';

  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import {
    TemplateFilter,
    AdvancedTemplateCard,
    AgencyTemplateFilter,
    CreateNewCard,
  } from '@/components/Template/';
  import NewTemplate from '@/components/Modals/NewTemplate.vue';
  import NameDuplicateTemplate from '@/components/Modals/NameDuplicateTemplate.vue';
  import UseCaseRow from '@/components/Template/UseCase/Row.vue';
  import NewUseCase from '@/components/Modals/NewUseCase.vue';
  import GeneralFilter from '@/components/Template/GeneralFilter.vue';
  import TagTemplateModal from '@/components/Modals/TagTemplateModal.vue';
  import NewTemplateCollectionModal from '@/components/Modals/NewTemplateCollection.vue';
  import TemplateCollectionRow from '@/components/Template/TemplateCollectionRow.vue';

  import observableCollectionMixin from '@/mixins/observableCollection';
  import templateManager from '@/mixins/templateManager';
  import useCaseMixin from '@/mixins/useCase';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import ssrMixin from '@/mixins/ssr';
  import bulkActionMixin from './bulk-action';
  import templateCollectionsMixin from './template-collections';
  import SegmentsTabContent from './Segments/TabContent.vue';

  export default {
    components: {
      NewCampaign,
      TemplateFilter,
      AdvancedTemplateCard,
      draggable,
      NewTemplate,
      NameDuplicateTemplate,
      AgencyTemplateFilter,
      CreateNewCard,
      UseCaseRow,
      NewUseCase,
      GeneralFilter,
      TagTemplateModal,
      NewTemplateCollectionModal,
      TemplateCollectionRow,
      SegmentsTabContent,
    },

    mixins: [
      templateManager,
      useCaseMixin,
      bulkActionMixin,
      templateCollectionsMixin,
      ssrMixin,
      observableCollectionMixin,
    ],

    apollo: {
      themes: {
        query: GET_TEMPLATE_THEMES,
      },
      useCase: {
        query: GET_USE_CASE,
      },
    },

    data() {
      return {
        type: this.$route.params.templateType || null,

        windowBasedHeight: 50,

        allTemplateGoals: [],
        allTemplateThemes: [],
        selectedGoals: [],
        allTemplateCategories: [],
        selectedCategories: [],

        templateTypes: [],
        templateGoals: [],
        templateCategories: [],
        templateFamilies: [],

        allTemplates: [],

        templates: {
          published: [],
          draft: [],
          unlisted: [],
          universal: [],
          partnerFeatured: [],
        },

        templatesLoading: true,
        noPermission: false,
        tab: 'live',
        users: [],
        selectedUsers: [],
        selectedThemes: [],
        selectedForDelete: null,
        inProgress: false,

        useCaseFilter: {
          name: '',
          goals: [],
          tactics: [],
          industries: [],
        },

        universalTplFilter: {
          type: { key: this.$t('popup'), value: 'popup' },
          themes: [],
          useCases: [],
        },

        templateKind: 'all',
      };
    },

    computed: {
      ...mapState(['accountType', 'locale']),
      ...mapGetters(['databaseId', 'isAffiliate']),
      mappedGoals() {
        return this.useCaseGoals.map((value) => this.transMapper(value, 'goals', 'templateFilter'));
      },
      mappedTactics() {
        return this.useCaseTactics.map((value) => this.transMapper(value, 'tactics'));
      },
      mappedIndustries() {
        return this.useCaseIndustries.map((value) => this.transMapper(value, 'industries'));
      },
      typeClass() {
        return `template-${this.type}`;
      },
      isAgency() {
        return this.accountType === 'agency';
      },
      isLiveTab() {
        return this.tab === 'live';
      },
      isDraftTab() {
        return this.tab === 'draft';
      },
      isUniversalTab() {
        return this.tab === 'universal';
      },
      isUseCaseTab() {
        return this.tab === 'useCase';
      },
      isUnlistedTab() {
        return this.tab === 'unlisted';
      },
      isCollectionsTab() {
        return this.tab === 'collections';
      },
      isSegmentsTab() {
        return this.tab === 'segments';
      },
      isPartnerFeaturedTab() {
        return this.tab === 'partnerFeatured';
      },
      useCaseFilterOpts() {
        return {
          goals: this.mappedGoals,
          tactics: this.mappedTactics,
          industries: this.mappedIndustries,
        };
      },
      useCasesFiltered: {
        get() {
          return (this.useCase || [])
            .filter((useCase) => {
              if (!this.useCaseFilter.name) return true;
              const nameFilter = this.useCaseFilter.name.replace(/\./gi, '\\.');
              if (
                new RegExp(nameFilter, 'ig').test(useCase.name.en) ||
                new RegExp(nameFilter, 'ig').test(useCase.name.hu)
              ) {
                return true;
              }

              return false;
            })
            .filter((useCase) => {
              let result = true;

              this.useCaseFilter.goals.forEach((item) => {
                result = result && useCase.goals.includes(item.value);
              });

              return result;
            })
            .filter((useCase) => {
              let result = true;

              this.useCaseFilter.tactics.forEach((item) => {
                result = result && useCase.tactics.includes(item.value);
              });

              return result;
            })
            .filter((useCase) => {
              let result = true;

              this.useCaseFilter.industries.forEach((item) => {
                result = result && useCase.industries.includes(item.value);
              });

              return result;
            });
        },
        set(v) {
          this.useCase = v;
        },
      },
      publishedTemplates() {
        return this.templates.published.filter(this.filterByTemplateKind);
      },
      draftTemplatesByKind() {
        return this.templates.draft.filter(this.filterByTemplateKind);
      },
      unlistedTemplates() {
        return this.templates.unlisted.filter(this.filterByTemplateKind);
      },
      partnerFeaturedTemplates() {
        return this.templates.partnerFeatured.filter(this.filterByTemplateKind);
      },
      sortedTemplateTypes() {
        const types = (this.templateTypes || [])
          .map((g) => ({
            key: this.$t(`${g.name}`),
            value: g.name,
          }))
          .sort((a, b) => a.key.localeCompare(b.key));

        types.unshift({ key: this.$t('all'), value: null });

        return types;
      },
      universalFilterOptions() {
        return {
          type: {
            select: true,
            multi: false,
            placeholder: this.$t('templateManager.selectWidgetType'),
            values: this.sortedTemplateTypes,
          },
          themes: {
            select: true,
            placeholder: this.$t('themes'),
            values: this.themes.map(({ name }) => ({ key: name, value: name })),
          },
          useCases: {
            select: true,
            placeholder: this.$t('useCase'),
            values: this.useCase.map(({ name, _id }) => ({
              key: name[this.$i18n.locale],
              value: _id,
            })),
          },
        };
      },
      universalPublished: {
        get() {
          return this.templates.published
            .filter(({ theme }) => {
              return this.universalTplFilter.themes.length
                ? this.universalTplFilter.themes.map(({ value }) => value).includes(theme)
                : true;
            })
            .filter(({ useCase }) => {
              return this.universalTplFilter.useCases.length
                ? this.universalTplFilter.useCases.map(({ value }) => value).includes(useCase)
                : true;
            });
        },
        set(value) {
          this.templates.published = value;
        },
      },
    },

    watch: {
      async type() {
        await this.fetchTemplates();
        this.onResize();
      },
      locale() {
        this.fetchTemplates();
      },
      selectedGoals: {
        handler() {
          this.fetchTemplates();
        },
        deep: true,
      },
      selectedCategories: {
        handler() {
          this.fetchTemplates();
        },
        deep: true,
      },
      selectedUsers: {
        handler() {
          this.fetchTemplates();
        },
        deep: true,
      },
      selectedThemes: {
        handler() {
          this.fetchTemplates();
        },
        deep: true,
      },
      tab(n, o) {
        const changeFromUni = o === 'universal';

        if (n === 'universal' || changeFromUni) {
          this.type = 'popup';
          this.selectedGoals = [];
          this.selectedCategories = [];
          this.selectedUsers = [];
          this.selectedthemes = [];
          this.universalTplFilter.type = { key: this.$t('popup'), value: 'popup' };
          this.universalTplFilter.themes = [];
          this.universalTplFilter.useCases = [];
          this.fetchTemplates({ type: 'popup' });
        }

        this.templateKind = 'all';

        history.replaceState({}, null, `${this.$route.path}?tab=${n}`);
      },
      'universalTplFilter.type': async function ({ value: type }) {
        await this.fetchTemplates({ type });
        this.onResize();
      },
    },

    async created() {
      this.tab = this.$route.query.tab || 'live';

      this.fetchAllGoals();
      this.fetchAllThemes();
      this.fetchAllCategories();
      await this.getAllSubAccounts();
    },

    mounted() {
      window.addEventListener('resize', this.onResize);
      this.fetchTemplates();
      this.$bus.$on('updateUseCase', this.updateUseCase);
      this.$bus.$on('fetchUseCases', this.fetchUseCases);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
      this.$bus.$off('updateUseCase', this.updateUseCase);
      this.$bus.$off('fetchUseCases', this.fetchUseCases);
    },

    methods: {
      async fetchUseCases() {
        await this.$apollo.queries.useCase.refetch();
      },
      async fetchTemplates(override = {}) {
        this.allTemplates = [];
        this.templatesLoading = true;

        const {
          data: {
            allTemplates: { types, goals, categories, templates },
          },
        } = await this.$apollo.query({
          query: ALL_TEMPLATES,
          variables: {
            type: this.type,
            goals: this.selectedGoals,
            categories: this.selectedCategories,
            locale: this.locale,
            users: this.selectedUsers,
            themes: this.selectedThemes,
            ...override,
          },
        });

        this.templateTypes = types;
        this.templateGoals = goals;
        this.templateCategories = categories;

        this.allTemplates = JSON.parse(JSON.stringify(templates));

        this.templates = { published: [], draft: [], unlisted: [], partnerFeatured: [] };

        if (this.allTemplates) {
          this.allTemplates.forEach((t) => {
            const template = this.overrideTemplateData(t);
            const type = this.getTemplateType(template);
            if (template.partnerFeatured && template.partnerFeatured.includes(this.databaseId)) {
              this.templates.partnerFeatured.push(template);
            }

            this.templates[type].push(template);
          });
        } else {
          this.noPermission = true;
        }

        Object.keys(this.templates).forEach((type) => this.orderTemplates(type));

        this.templatesLoading = false;
      },
      async fetchAllGoals() {
        const {
          data: { allTemplateGoals },
        } = await this.$apollo.query({
          query: ALL_TEMPLATE_GOALS,
        });

        if (allTemplateGoals.success) {
          this.allTemplateGoals = allTemplateGoals.items;
        } else {
          this.allTemplateGoals = [];
        }
      },
      async fetchAllThemes() {
        const {
          data: { allTemplateThemes },
        } = await this.$apollo.query({ query: ALL_TEMPLATE_THEMES });
        console.log('@@@@allTemplateThemes', allTemplateThemes);
        this.allTemplateThemes = allTemplateThemes.items;
      },
      async fetchAllCategories() {
        const {
          data: { allTemplateCategories },
        } = await this.$apollo.query({
          query: ALL_TEMPLATE_CATEGORIES,
        });

        if (allTemplateCategories.success) {
          this.allTemplateCategories = allTemplateCategories.items;
        } else {
          this.allTemplateCategories = [];
        }
      },

      onResize() {
        this.$nextTick(() => {
          if (this.type === 'nanobar' && this.$refs.previewBox) {
            this.windowBasedHeight =
              0.03367 * this.$refs.previewBox[0].$el.getBoundingClientRect().width;
          }
        });
      },
      onTemplateDragChange(params, type) {
        if (params.moved) {
          const orders = this.templates[type].map((t, i) => {
            return { id: t._id, order: i };
          });

          this.$apollo.mutate({
            mutation: CHANGE_TEMPLATE_ORDER,
            variables: {
              orders,
            },
          });
        }
      },
      onUseCaseDragChange(params) {
        if (params.moved) {
          const orders = this.useCase.map((t, i) => {
            return { id: t._id, order: i };
          });

          this.$apollo.mutate({
            mutation: CHANGE_USECASE_ORDER,
            variables: {
              orders,
            },
          });
        }
      },

      overrideTemplateData(template) {
        const { agencySettings } = template;

        if (agencySettings) {
          const relevantSetting = agencySettings.find((d) => d.databaseId === this.databaseId);
          if (relevantSetting) {
            delete relevantSetting._id;
            template = {
              ...template,
              ...relevantSetting,
            };
          }
        }
        return template;
      },

      orderTemplates(type) {
        this.templates[type] = this.templates[type].sort((a, b) => {
          if (a.order === b.order) {
            return a.name.localeCompare(b.name);
          }

          return a.order - b.order;
        });
      },
      moveTemplateToCorrespondingArray({
        templateId,
        oldType,
        newType,
        field,
        value,
        removeFromOld = true,
        addToNew = true,
      }) {
        const index = this.templates[oldType].findIndex((t) => t._id === templateId);
        if (index !== -1) {
          this.$set(this.templates[oldType][index], field, value);

          if (addToNew) {
            this.templates[newType].push(this.templates[oldType][index]);
          }

          if (removeFromOld) {
            this.templates[oldType].splice(index, 1);
          }

          this.orderTemplates(newType);
        }
      },
      templateChanged({
        templateId,
        type,
        displayName,
        name,
        goals,
        categories,
        locale,
        users,
        theme,
        useCase,
        thumbnail,
        tags,
      }) {
        const index = this.templates[type].findIndex((t) => t._id === templateId);

        if (index !== -1) {
          if (locale !== this.templates[type][index].locale) {
            this.templates[type].splice(index, 1);
          } else {
            this.$set(this.templates[type][index], 'displayName', displayName);
            this.$set(this.templates[type][index], 'name', name);
            this.$set(this.templates[type][index], 'goals', goals);
            this.$set(this.templates[type][index], 'categories', categories);
            this.$set(this.templates[type][index], 'users', users);
            this.$set(this.templates[type][index], 'tags', tags);

            const isUniversal = _get(this.templates[type][index], 'universal', false);

            if (isUniversal || useCase || theme || thumbnail) {
              this.$set(this.templates[type][index], 'theme', theme || null);
              this.$set(this.templates[type][index], 'useCase', useCase || null);
              this.$set(this.templates[type][index], 'thumbnail', thumbnail || null);
            }
          }
        }
      },
      templateDuplicated({ template }) {
        this.tab = 'draft';
        template.author = { databaseId: this.databaseId };
        this.templates.draft.push(template);
      },
      canEdit(template) {
        return template.author && template.author.databaseId === this.databaseId;
      },

      deleteConfirmOpen(id) {
        this.selectedForDelete = id;
        this.$modal.show('template-delete-confirm');
      },

      async deleteTemplate(id) {
        this.inProgress = true;
        const templateId = this.selectedForDelete || id;
        const {
          data: { deleteTemplate },
        } = await this.$apollo.query({
          query: DELETE_TEMPLATE,
          variables: { templateId },
        });
        if (deleteTemplate && deleteTemplate.success) {
          const draftIndex = this.templates.draft.findIndex((t) => t._id === templateId);
          const publishedIndex = this.templates.published.findIndex((t) => t._id === templateId);
          const featuredIndex = this.templates.partnerFeatured.findIndex(
            (t) => t._id === templateId,
          );

          if (draftIndex !== -1) {
            this.templates.draft.splice(draftIndex, 1);
          } else if (publishedIndex !== -1) {
            this.templates.published.splice(publishedIndex, 1);
          } else if (featuredIndex !== -1) {
            this.templates.partnerFeatured.splice(featuredIndex, 1);
          }
        }
        this.selectedForDelete = null;
        this.$modal.hide('template-delete-confirm');
        this.inProgress = false;
      },

      async getAllSubAccounts() {
        const {
          data: { subAccounts },
        } = await this.$apollo.query({
          query: GET_ALL_SUBACCOUNTS,
        });
        this.users = subAccounts;
      },

      openTemplateModal() {
        this.$modal.show('new-template', {
          agency: this.isAgency,
        });
      },
      onOpenUseCase(instance) {
        this.$refs.useCases.forEach((u) => {
          u.opened = false;
        });
        instance.opened = true;
      },
      onEditUseCase(data) {
        this.$modal.show('new-use-case', { mode: 'edit', data });
      },
      transMapper(value, sub, domain = 'useCaseManager') {
        const key = this.$t(`${domain}.${sub}.${value}`);

        return { key, value };
      },

      showUseCaseRemoveDialog(data) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: 'btn btn-primary mr-3 steve',
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: REMOVE_USE_CASE,
                    variables: { id: data._id },
                  })
                  .then(({ data: response }) => {
                    if (response.result) {
                      this.useCase = this.useCase.filter(({ _id }) => _id !== data._id);
                      this.$modal.hide('dialog');
                    }
                  })
                  .catch(console.error);
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },

      updateUseCase(data) {
        this.useCase = this.useCase.map((uc) => {
          if (data.id === uc._id) {
            return { ...uc, ...data.settings };
          }
          return uc;
        });
      },

      updateTemplateCollection(settings) {
        this.templateCollections = this.templateCollections.map((uc) => {
          if (settings._id === uc._id) {
            return { ...uc, ...settings };
          }
          return uc;
        });
      },

      updateTemplates({ templates, tags }) {
        templates.forEach((templateId) => {
          const template = this.allTemplates.find((t) => t._id.toString() === templateId);
          const type = this.getTemplateType(template);
          const index = this.templates[type].findIndex((t) => t._id.toString() === templateId);
          if (index !== -1) {
            this.$set(this.templates[type][index], 'tags', [
              ...new Set([...(this.templates[type][index].tags || []), ...tags]),
            ]);
          }
        });
      },

      filterByTemplateKind(template) {
        if (this.templateKind === 'all') return true;
        if (this.templateKind === 'universal') return template.universal;
        return !template.universal;
      },
    },
  };
</script>

<style lang="sass">
  $flagsImagePath: '~intl-tel-input/build/img/'
  @import '~intl-tel-input/src/css/intlTelInput'
  @import './src/sass/pages/_template_sharing.sass'
</style>
